<template>
  <div
    class="godetectv8 px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <TitleComponent title="GoDetect Version 8!" />
        <Paragraph
          ><p>
            <a
            class="link"
            href="http://godetect.smsenergetics.com/"
            target="_blank"
            >
            GoDetect Version 8</a> continues to be an automated, non-subjective method for reaction detection using the <router-link to="/test-equipment/abl-esd">ABL ESD</router-link>
            machine and it is a significant upgrade to the previous version in that:
            <ul>
              <li> The reaction determination is more accurate as benchmarked using a gas analyzer for many different carbon containing substances.</li>
              <li> The reaction level, or "GO" level, can be set at any of 3 different levels that are based on a reaction amount. Previously it was a binary determination.</li>
              <li> The latest in machine learning or image classification techniques has been used to train an effective model that is used in the software.</li>
              <li> Software updates can more easily be installed using Docker Desktop.</li>
            </ul>

            For more information, please explore the example application on the web           
            <a
            class="link"
            href="http://godetect.smsenergetics.com/"
            target="_blank"
            >here.</a>
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <a href="http://godetect.smsenergetics.com/" target="_blank" aria-label="GoDetectLink">
        <img src="@/assets/TestEquipment/CaptureGDH-min.png" alt=""/> </a>
      </div>      
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "GoDetect8",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "Updated GoDetect version 8 with links Blog entry."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.godetectv8 {
  a {
    color: #aa222c;
  }
}

</style>
